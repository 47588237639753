<template>

</template>

<script>
export default {
    created(){
        window.location.replace('https://h5.tc-rescue.com/rescue?1df844347c23254983ff10cd500b4f548dbf90a36a6e0e8eeeb7a6072c29ac07');
    }
}
</script>

<style>

</style>
